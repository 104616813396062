import Device from "@/pure/Device";
import { Vector2 } from "three";

class Input {
  constructor() {
    this.mouseMoved = false;
    this.coords = new Vector2();
    this.coords_old = new Vector2();
    this.diff = new Vector2();
    this.timer = null;
    this.count = 0;
    this.velocity = 0;
    this.offset = new Vector2(0, 0);
  }

  init() {
    document.body.addEventListener('mousemove', this.onDocumentMouseMove.bind(this), false);
    document.body.addEventListener('touchstart', this.onDocumentTouchStart.bind(this), false);
    document.body.addEventListener('touchmove', this.onDocumentTouchMove.bind(this), false);
  }

  setCoords(x, y) {
    if (this.timer) clearTimeout(this.timer);
    this.coords.set((x / Device.viewport.width) * 2 - 1, - (y / Device.viewport.height) * 2 + 1);
    this.mouseMoved = true;
    this.timer = setTimeout(() => {
      this.mouseMoved = false;
    }, 100);

  }
  onDocumentMouseMove(event) {
    this.setCoords(event.clientX, event.clientY);
  }
  onDocumentTouchStart(event) {
    if (event.touches.length === 1) {
      // event.preventDefault();
      this.setCoords(event.touches[0].pageX, event.touches[0].pageY);
    }
  }
  onDocumentTouchMove(event) {
    if (event.touches.length === 1) {
      // event.preventDefault();

      this.setCoords(event.touches[0].pageX, event.touches[0].pageY);
    }
  }

  render() {
    this.diff.subVectors(this.coords, this.coords_old);
    this.coords_old.copy(this.coords);

    if(this.coords_old.x === 0 && this.coords_old.y === 0) this.diff.set(0, 0);
  }
  dispose() {
  }
  resize() { }
}

export default new Input();
