// import gsap from "@gsap/shockingly";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Graphics from "@/graphics/index";

const app = createApp(App);
app.provide('graphics', new Graphics());
app.use(router);
app.mount('#app');




