import {
    Group,
    Mesh,
    MeshBasicMaterial,
    Vector2,
    Vector3,
    Shape,
    ShapeGeometry,
    Raycaster,
    Float32BufferAttribute,
    BufferGeometry,
    LineSegments,
    LineBasicMaterial,
    Color,
    RawShaderMaterial,
    GLSL3
} from "three";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

import * as d3 from "d3";
import * as topojson from "topojson-client";
import gsap from "gsap";

import Common from "@/graphics/Common";
import Assets from "@/graphics/Assets";
import Device from "@/pure/Device";

import { getList, getTopVote, properties, highlightDepartment, heads, offset } from "@/pure/data.gouv";

import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";

export default class Map extends Group {
    PARAMS = {
        fontScale: 0.009,
        groundColor: 0xd3c0af
    };
    data = [];
    
    projection = d3
        .geoConicConformal()
        .center([2.454071, 46.279229])
        .scale(3600)
        .translate([0, 0])
        .reflectY(true);

    geoPath = d3.geoPath(this.projection);
    
    lineMaterial = new LineBasicMaterial({ color: 0xa3aaaa, transparent: true });

    interactives = [];
    raycaster = new Raycaster();
    pointer = new Vector2();
    INTERSECTED = null;

    material2 = new RawShaderMaterial({
        glslVersion: GLSL3,
        transparent: true,
        uniforms: {
            resolution: {
                value: new Vector3(Device.viewport.width, Device.viewport.height, Device.viewport.width / Device.viewport.height)
            },
            time: {
                value: 0
            },
            iIdx: {
                value: -1
            }
        },
        vertexShader,
        fragmentShader,
      });

    constructor() {
        super();
        Common.scene.add(this);
        this.material = new MeshBasicMaterial({ transparent: true, vertexColors: true }); 
        this.init();
        this.resize();
    }
    async init() {
        const EUfeatures = await Assets.loadTopology("/assets/europe.json");
        const EUShapes = [];
        for (const feature of EUfeatures.features.filter(_ => _.id !== 'FR')) {
            let shape = this.createShape(feature.geometry, new Color(this.PARAMS.groundColor).getHex());
            EUShapes.push(shape);
        }
        this.mergeShapes(EUShapes);

        this.data = await Assets.loadFRData("/assets/resultats-definitifs-par-departement.csv");
        this.liste = getList(this.data[0]);

        this.candidats = await Assets.loadFRData("/assets/candidats-eur-2024.csv");

        const topology = await Assets.loadTopology("/assets/departements.json");
        let features = topojson.feature(topology, topology.objects.departements);
        // const features = await Assets.loadTopology("/assets/departements-simple.json");

        const voted = [];
        const uniqueVoted = new Set();

        const shapes = [];
        const lineGeom = [];
        for (const feature of features.features) {
            const departementData = this.data.find(_ => _['Code département'] === feature.properties.code);
            const topVote = getTopVote(departementData, this.liste);
            uniqueVoted.add(topVote);
            voted.push(topVote);
            const property = properties.find(_ => _.panel == (topVote + 1));

            let shape = this.createShape(feature.geometry, new Color(property.color).getHex(), features.features.indexOf(feature));
            shape.userData = feature.properties;
            const centroid = this.geoPath.centroid(feature.geometry);
            const bounds = this.geoPath.bounds(feature.geometry);
            shape.userData.centroid = centroid;
            shape.userData.bounds = bounds;

            shapes.push(shape);

            let line = this.createLine(feature.geometry);
            // this.add(line);
            lineGeom.push(line);
        }
        this.departementsShapes = shapes;
        const departments = this.mergeShapes(shapes);
        this.interactives.push(departments);


        properties.forEach((property) => {
            const count = voted.filter(vote => vote + 1 == property.panel).length;
            property.count = count;
            const candidat = this.candidats.find(candidat => candidat["Numéro de panneau"] == property.panel);
            property.headline = `${candidat["Prénom sur le bulletin de vote"]} ${candidat["Nom sur le bulletin de vote"]}`;
        })
        heads.push(...properties);
        this.boundPointermove = this.handlePointermove.bind(this);
        document.addEventListener("pointermove", this.boundPointermove, false);
        this.boundPointerdown = this.handlePointerdown.bind(this);
        document.addEventListener("pointerdown", this.boundPointerdown, false);

        const features3 = await Assets.loadTopology("/assets/circonscriptions-legislatives-2012.json");
        // let features3 = topojson.feature(topology3, topology.objects.departements);
        // const features = await Assets.loadTopology("/assets/departements-simple.json");
        const shapes3 = [];
        const lineGeom3 = [];
        for (const feature of features3.features) {
            let shape = this.createShape(feature.geometry, new Color(this.PARAMS.groundColor).getHex());
            shapes3.push(shape);
            let line = this.createLine(feature.geometry);
            // this.add(line);
            lineGeom3.push(line);
            
        }
        const lines3 = this.mergeLines(lineGeom3);
        // console.log(li);
        lines3.material = this.lineMaterial.clone();
        // lines3.material.color = new Color(0xb4bbbb);
        lines3.material.opacity = 0.1;
        this.add(lines3);
        // this.mergeShapes(shapes3);
        const lines = this.mergeLines(lineGeom);
        // lines.material = this.lineMaterial.clone();
        // lines.material.color = new Color(0x0000ff);
        // lines.position.z = 0;
        this.add(lines);
    }
    project([longitude, latitude]) {
      const point = this.projection([longitude, latitude]);
      const [x, y] = point;
      return [
        x,
        y
      ];
    }
    createLine(featureGeometry) {
        var geometry = new BufferGeometry();
        const positions = [];    
        for (let P of featureGeometry.coordinates) {
            if(featureGeometry.type === "MultiPolygon"){
                P = P[0];
            }
            let p0 = [P[0][0], P[0][1]];
            for (let i = 1; i < P.length; ++ i) {
                let p1 = [P[i][0], P[i][1]];
                positions.push(...this.project(p0), 0.01, ...this.project(p1), 0.01);
                p0 = p1;
            }
        }
        geometry.setAttribute( 'position', new Float32BufferAttribute( positions, 3 ) );
        return geometry;
    }
    mergeLines(shapes) {
        const geom = BufferGeometryUtils.mergeGeometries(shapes);
        const mesh = new LineSegments(geom, this.lineMaterial);
        return mesh;
    }
    createShape(featureGeometry, color, i = -2) {
        let shapearray = [];
        
        for (let P of featureGeometry.coordinates) {
            if(featureGeometry.type === "MultiPolygon") {
                P = P[0];
            } 
            const shape = new Shape();
            let p0 = [P[0][0], P[0][1]];
            for (let i = 1; i < P.length; ++ i) {
                let p1 = [P[i][0], P[i][1]];
                if (i === 1) {
                    shape.moveTo(...this.project(p0));
                } else  {
                    shape.lineTo(...this.project(p0))
                }
                p0 = p1;
            }

            shapearray.push(shape);
        }
        let shapeGeo = new ShapeGeometry(shapearray);
        const pos = shapeGeo.getAttribute('position');
        const indices = new Array(pos.count).fill(i);
        shapeGeo.setAttribute( 'idx', new Float32BufferAttribute( indices, 1 ) );

        const colors = [];
        for (let y = 0; y < pos.count; y++) {
            const col = new Color(color);
            colors.push(...col.toArray());
        }
        shapeGeo.setAttribute( 'color', new Float32BufferAttribute( colors, 3 ) );
        return shapeGeo;
    }
    mergeShapes(shapes) {
        const geom = BufferGeometryUtils.mergeGeometries(shapes);
        const mesh = new Mesh(geom, this.material2);
        this.add(mesh);
        return mesh;
    }
    handlePointermove(event) {
        event.preventDefault();
        this.pointer.set(
            ( event.clientX / Device.viewport.width ) * 2 - 1,
            - ( event.clientY / Device.viewport.height ) * 2 + 1
        );

        this.raycaster.setFromCamera(this.pointer, Common.camera);

        let intersects = this.raycaster.intersectObjects(this.interactives);

        if (intersects.length > 0) {
            document.body.style.cursor = 'pointer';

            const intersect = intersects[ 0 ];
            const { faceIndex, object } = intersect;
            const { geometry } = object;
            const { idx } = geometry.attributes;
            const id = idx.array[geometry.index.array[faceIndex * 3]];
            highlightDepartment.value = this.departementsShapes[id].userData;
            this.material2.uniforms.iIdx.value = id;
        } else {
            highlightDepartment.value = null;
            this.material2.uniforms.iIdx.value = -1;
        }
    }
    handlePointerdown(event) {
        event.preventDefault();
        this.pointer.set(
            ( event.clientX / Device.viewport.width ) * 2 - 1,
            - ( event.clientY / Device.viewport.height ) * 2 + 1
        );

        this.raycaster.setFromCamera( this.pointer, Common.camera );
        const intersects = this.raycaster.intersectObjects( this.interactives, false );

        if ( intersects.length > 0 ) {
            const intersect = intersects[ 0 ];
            const { faceIndex, object } = intersect;
            const { geometry } = object;
            const { idx } = geometry.attributes;
            const id = idx.array[geometry.index.array[faceIndex * 3]];
            this.center(this.departementsShapes[id].userData.bounds);
        }
    }
    center(bounds) {
        const [[x0, y0], [x1, y1]] = bounds;
        const scale = Math.min(
            20,
            0.9 / Math.max((x1 - x0) / (Device.viewport.width * 0.5), (y1 - y0) / (Device.viewport.height * 0.5))
        );

        const x = -(x0 + x1) / 2 * scale;
        const y = -(y0 + y1) / 2 * scale;
        const PARAMS = {
            x: this.position.x,
            y: this.position.y,
            scale: this.scale.x
        }
        gsap.to(PARAMS, {
            x,
            y,
            scale,
            duration: 0.5,
            ease: "sine.inOut",
            onUpdate: () => {
                this.position.x = PARAMS.x;
                this.position.y = PARAMS.y;
                this.scale.set(PARAMS.scale, PARAMS.scale, 1);
                offset.x = PARAMS.x + 'px';
                offset.y = -PARAMS.y + 'px';
                offset.scale  = PARAMS.scale;
            }
        });
        
    }
    render(t) {
        this.material2.uniforms.time.value = t * 0.025;

        // console.log(Common.renderer.info.render.calls);
    }
    resize() {
        this.material2.uniforms.resolution.value.set(Device.viewport.width, Device.viewport.height, Device.viewport.width / Device.viewport.height);
    }
    dispose() {
        document.removeEventListener("pointermove", this.boundPointermove);
        document.removeEventListener("pointerdown", this.boundPointerdown);
        Common.scene.remove(this);
    }
}