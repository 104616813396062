<template>
  <div class="home">
    <h1>ÉLÉCTIONS EUROPÉENNES 2024</h1>
    <div class="heads">
      <a
        :key="panel"
        v-for="({ panel, image, color, url, name, count, headline }) in sortedHeads"
        class="head"
        target="_blank"
        :href="url"
        :style="{
          '--color': color
        }"
      >
        <div class="vignette">
          <img
            :src="image"
          />
        </div>
        <div>
          <h2 class="headline">{{ headline }}</h2>
          <h2>{{ name }}  <span class="small">({{ count }} département{{ count > 1 ? 's' : '' }})</span></h2>
        </div>
      </a>
    </div>
    <div class="sub-title">Liste en tête par département</div>
    <div
      v-if="highlightDepartment"
      class="highlight"
      :style="{
        '--offsetX': offset.x,
        '--offsetY': offset.y,
        '--scale': offset.scale,
      }"
    >
      <div
        class="label"
        :style="{
          '--left': highlightDepartment.centroid[0] + 'px',
          '--top': -highlightDepartment.centroid[1] + 'px'
        }"
      >
        {{ `${highlightDepartment.nom} ${highlightDepartment.code}`   }}
      </div>
    </div>
    <div class="footer">
      Source: <a target="_blank" href="https://www.data.gouv.fr/fr/datasets/resultats-des-elections-europeennes-du-9-juin-2024/">data.gouv.fr</a> - images: <a target="_blank" href="https://www.lemonde.fr/les-decodeurs/article/2024/06/07/qui-sont-les-candidats-aux-elections-europeennes-de-2024_6190102_4355771.html">lemonde.fr</a> - développé par <a target="_blank" href="https://trinketmage.github.io/">Rémi Tran</a>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { highlightDepartment, heads, offset } from '@/pure/data.gouv'
export default {
  name: 'HomeView',
  setup() {
    const sortedHeads = computed(() => {
      return heads.filter(_ => _.count > 0).sort((a, b) =>{
        return b.count - a.count
      })
    });
    return {
      highlightDepartment,
      sortedHeads,
      offset
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
h1 {
  margin: 0;
  font-family: 'Monument';
  font-size: 4.25vw;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f3ece0;
}
.heads {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 3.5vw 1.25vw;
}
.head {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
  align-self: flex-start;
  .vignette {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    font-size: 0;
    display: block;
    background-color: var(--color);
  }
  h2 {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    text-shadow: 0 0 1px #f3ece0;
  }
  .headline {
    font-weight: 900;
  }
  .small {
    font-weight: 400;
    font-size: 14px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.5vw;
  font-size: 11px;
}
.sub-title {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.25vw;
  font-family: 'Monument';
  text-transform: uppercase;
  font-size: 24px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f3ece0;
}
.highlight {
  position: fixed;
  top: 50%;
  left: 50%;
  --offsetX: 100px;
  --offsetY: 0px;

  .label {
    position: absolute;
    background-color: #f3ece0;
    border: 1px solid rgb(32, 33, 34);
    padding: 4px 8px;
    transform: translateX(-50%) translateY(-50%);
    margin-top: -20px;
    white-space: nowrap;
    font-size: 13px;
    left: calc(var(--left) * var(--scale) + var(--offsetX));
    top: calc(var(--top) * var(--scale) + var(--offsetY));
    &:before {
      display: block;
      content: "";
      width: 0; 
      height: 0; 
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 5px solid #f3ece0;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -8px;
      z-index: 1;
    }
    &:after {
      display: block;
      content: "";
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 6px solid rgb(32, 33, 34);
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -10px;
      z-index: 0;
    }
  }
}
</style>
