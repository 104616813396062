import Device from "@/pure/Device";

import {
  Scene,
  WebGLRenderer,
  Color,
  // PerspectiveCamera,
  OrthographicCamera,
  // ACESFilmicToneMapping,
} from "three";

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { getFovHeigth } from "@trinketmage/sword";
import { Pane } from "tweakpane";

class Common {
  scene = new Scene();
  handle = document.body.querySelector('#graphics');

  constructor() {
    // this.scene.background = new Color(0x050505);
    // this.scene.background = new Color(0x030c1e);
    // this.scene.background = new Color(0xdaa520);
    // this.scene.background = new Color(0xdaa520);
    this.scene.background = new Color(0xacbabb);
    // this.scene.background = new Color(0xd5d7cc);
    const { width, height } = Device.viewport;

    this.camera = new OrthographicCamera(
      width * -0.5, width * 0.5, height * 0.5, height * -0.5, 0.1, 100 
    );

    // this.camera = new PerspectiveCamera(
    //   50,
    //   Device.viewport.width / Device.viewport.height,
    //   0.01,
    //   100.0
    // );
    this.camera.position.z = 1;
  }

  init() {
    this.renderer = new WebGLRenderer({
      // canvas: canvas,
      alpha: false,
      stencil: false,
      depth: true,
      powerPreference: "high-performance",
      antialias: false
    });
    this.handle.appendChild( this.renderer.domElement );

    this.renderer.autoClear = false;
    this.renderer.physicallyCorrectLights = true;
    // this.renderer.toneMapping = ACESFilmicToneMapping;

    this.renderer.setPixelRatio(Device.pixelRatio);

    this.debug = window.location.hash === '#debug' ? new Pane() : null;
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  dispose() {
    this.handle.removeChild( this.renderer.domElement );
    this.renderer.dispose();
    this.debug?.dispose();
  }

  resize() {
    Device.viewport.width = this.renderer.domElement.parentElement.offsetWidth;
    Device.viewport.height = this.renderer.domElement.parentElement.offsetHeight;
    const { width, height } = Device.viewport;


    // this.camera.aspect =
    //   Device.viewport.width / Device.viewport.height;

    this.camera.left = width * -0.5;
    this.camera.right = width * 0.5;
    this.camera.top = height * 0.5;
    this.camera.bottom = height * -0.5;

    this.camera.updateProjectionMatrix();

    Device.fov.height = getFovHeigth(this.camera, 10);
    Device.fov.width = Device.fov.height * this.camera.aspect;

    this.renderer.setSize(
      Device.viewport.width,
      Device.viewport.height
    );
  }
}

export default new Common();
