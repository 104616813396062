<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
    font-family: "Monument";
    src: url("@/assets/fonts/PPMonumentExtended-Black.otf") format("opentype");
    font-style: normal;
    font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  color: #202122;
  font-family: "Roboto", sans-serif;
  touch-action: none;
}

#graphics {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 0;
  height: 100vh;
  background-color: #030c1e;
}
</style>
