import WebGPU from 'three/addons/capabilities/WebGPU.js';

export default {
  WebGPU: {
    isAvailable: WebGPU.isAvailable()
  },
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  fov: {
    width: 1,
    height: 1,
  },
  pixelRatio: Math.min(window.devicePixelRatio, 2),
}