import Input from "./Input";
import Common from "./Common";
import Output from "./Ouput";

import gsap from "gsap";

Math.TAU = Math.PI * 2;

export default class {
  constructor() {
    Input.init();
    Common.init();

    this.output = new Output();

    this.init();
  }
  init() {
    this.resize();
    this.x = this.resize.bind(this);
    window.addEventListener("resize", this.x, false);

    this.boundRender = this.render.bind(this);
    gsap.ticker.add(this.boundRender);
  }
  render(t, d) {
    Input.render(t, d);
    this.output.render(t, d);
  }
  resize() {
    
    Input.resize();
    Common.resize();
    this.output.resize();
  }
  dispose() {
    window.removeEventListener("resize", this.x);
    gsap.ticker.remove(this.boundRender);

    Input.dispose();
    Common.dispose();
    this.output.dispose();
  }
}
