import { ref, reactive } from "vue";

const length = 38;

export const properties = [
    {
        panel: "3",
        color: "#08084d",
        url: "https://www.parti-reconquete.fr/",
        name: "Reconquête",
        image: "https://assets-decodeurs.lemonde.fr/decodeurs/medias/ganaches%20europ%C3%A9ennes%202024/marechal.png"
    },
    {
        panel: "4",
        color: "#E3051B",
        url: "https://lafranceinsoumise.fr/",
        name: "La France Insoumise",
        image: "https://assets-decodeurs.lemonde.fr/decodeurs/medias/ganaches%20europ%C3%A9ennes%202024/aubry.png"
    },
    {
        panel: "5",
        color: "#002e61",
        url: "https://rassemblementnational.fr/",
        name: "Rassemblement National",
        image: "https://assets-decodeurs.lemonde.fr/decodeurs/medias/ganaches européennes 2024/bardella.png"
    },
    {
        panel: "11",
        color: "#9859fc",
        url: "https://besoindeurope.fr/",
        name: "Besoin d’Europe",
        image: "https://assets-decodeurs.lemonde.fr/decodeurs/medias/ganaches%20europ%C3%A9ennes%202024/hayer.png"
    },
    {
        panel: "27",
        color: "#ff6daa",
        url: "https://www.glucksmann2024.eu/",
        name: "Réveiller l'Europe",
        image: "https://assets-decodeurs.lemonde.fr/decodeurs/medias/ganaches%20europ%C3%A9ennes%202024/glucksmann.png"
    }
];

export const getList = function(row) {
    const list = [];
    for(let i = 0; i < length; i++) {
        list.push({
            label: row[`Libellé de liste ${i + 1}`],
            shortLabel: row[`Libellé abrégé de liste ${i + 1}`],
            panel: row[`Numéro de panneau ${i + 1}`],
        });
    }
    return list;
}

export const getTopVote = function(row) {
    const list = [];
    for(let i = 0; i < length; i++) {
        let str = row[`% Voix/exprimés ${i + 1}`].replace(",", ".").replace("%", "");
        str = Number.parseFloat(str) * 0.01;
        list.push(str);
    }
    const highest = Math.max(...list);

    const idx = list.findIndex(_ => _ === highest);
    return idx;
}

export const offset = reactive({ x: '0px', y: '0px', scale: 1 });
export const highlightDepartment = ref(null);
export const heads = reactive([]);